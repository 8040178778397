// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".close-icon {\n  position: absolute;\n  top: 10px;\n  right: 10px;\n}\n.preview_dimensions {\n  height: 100%;\n}\n@media (min-width: 960px) {\n.preview_dimensions {\n    width: 76%;\n}\n}\n@media (max-width: 959px) {\n.preview_dimensions {\n    width: 100%;\n}\n}\n.opaque-cover {\n  opacity: 0;\n  height: 60px;\n  width: 40%;\n  left: 60%;\n  position: absolute;\n}\n@media screen and (max-width: 500px) {\n.opaque-cover {\n    left: 0;\n    width: 100%;\n}\n}", ""]);
// Exports
exports.locals = {
	"primary": "#0099dc",
	"orange": "#FC6222",
	"decline": "#ff4848"
};
module.exports = exports;
