var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-card",
        {
          staticStyle: { height: "90vh" },
          on: {
            contextmenu: function ($event) {
              return _vm.handler($event)
            },
          },
        },
        [
          _c(
            "v-btn",
            {
              staticClass: "close-icon",
              attrs: { icon: "" },
              on: {
                click: function ($event) {
                  return _vm.$emit("cancel")
                },
              },
            },
            [_c("v-icon", [_vm._v("close")])],
            1
          ),
          _c("v-card-title", [_vm._v(_vm._s(_vm.title))]),
          _c(
            "v-card-text",
            { staticStyle: { height: "calc(100% - 150px)" } },
            [
              _vm.isLecture
                ? _c(
                    "div",
                    { staticClass: "hidden-sm-and-down" },
                    [
                      !_vm.showAnnotation
                        ? _c(
                            "v-tooltip",
                            {
                              attrs: { top: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function ({ on, attrs }) {
                                      return [
                                        _c(
                                          "v-btn",
                                          _vm._g(
                                            _vm._b(
                                              {
                                                staticClass: "v-card--raised",
                                                staticStyle: {
                                                  "margin-top": "22%",
                                                  "margin-right":
                                                    "calc(11% + 4px)",
                                                },
                                                attrs: {
                                                  fab: "",
                                                  "x-large": "",
                                                  icon: "",
                                                  rounded: "",
                                                  dark: "",
                                                  absolute: "",
                                                  right: "",
                                                  top: "",
                                                  color: "#FF8855",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.$emit(
                                                      "show-annotation"
                                                    )
                                                  },
                                                },
                                              },
                                              "v-btn",
                                              attrs,
                                              false
                                            ),
                                            on
                                          ),
                                          [
                                            _c("v-icon", [
                                              _vm._v(" mdi-notebook-plus "),
                                            ]),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                2601288644
                              ),
                            },
                            [_c("span", [_vm._v("Notes")])]
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.isVideo
                ? _c(
                    "v-container",
                    { staticClass: "fill-height" },
                    [
                      _c(
                        "v-row",
                        {
                          staticStyle: { height: "100%" },
                          attrs: { justify: "center" },
                        },
                        [
                          _c(
                            "video",
                            {
                              ref: "video-control",
                              staticClass: "preview_dimensions",
                              staticStyle: {
                                "border-style": "solid",
                                "border-width": "1px",
                                "border-color": "#e0e0e0",
                              },
                              attrs: { controls: "" },
                            },
                            [
                              _vm._v(
                                " Your browser does not support the video tag. "
                              ),
                            ]
                          ),
                          _c(
                            "v-row",
                            { staticClass: "hidden-md-and-up mt-2 ml-2" },
                            [
                              _vm._v(
                                "Uploaded on " +
                                  _vm._s(_vm.shareDate.replaceAll("-", "/"))
                              ),
                            ]
                          ),
                          _vm._t("lecture_is_video"),
                        ],
                        2
                      ),
                    ],
                    1
                  )
                : _c(
                    "v-container",
                    { staticClass: "fill-height" },
                    [
                      _c(
                        "v-row",
                        {
                          staticStyle: { height: "100%" },
                          attrs: { justify: "center" },
                        },
                        [
                          _vm.isImg
                            ? _c("v-img", {
                                staticClass: "preview_dimensions",
                                attrs: { contain: "", src: _vm.previewUrl },
                              })
                            : _c("iframe", {
                                ref: "iframe",
                                staticClass: "preview_dimensions",
                                attrs: { src: _vm.previewUrl },
                              }),
                          _c("div", { staticClass: "opaque-cover" }, [
                            _vm._v(
                              _vm._s(this.width) + " x " + _vm._s(this.height)
                            ),
                          ]),
                          _c(
                            "v-row",
                            { staticClass: "hidden-md-and-up mt-2" },
                            [_vm._v("Uploaded on " + _vm._s(_vm.shareDate))]
                          ),
                          _vm._t("lecture_is_not_video"),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
            ],
            1
          ),
          _vm.isDownload
            ? _c(
                "v-card-actions",
                [
                  _c("v-btn", { on: { click: _vm.download } }, [
                    _vm._v(_vm._s(!_vm.$language ? "Download" : "ডাউনলোড")),
                  ]),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "v-card",
        { staticClass: "hidden-md-and-up", staticStyle: { width: "90vw" } },
        [_vm._t("lecture_mobile")],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }