<template>
  <div>
    <v-card style="height: 90vh" @contextmenu="handler($event)">
      <v-btn icon class="close-icon" @click="$emit('cancel')"
        ><v-icon>close</v-icon></v-btn
      >
      <v-card-title>{{ title }}</v-card-title>
      <v-card-text style="height: calc(100% - 150px)">
        <div v-if="isLecture" class="hidden-sm-and-down">
          <v-tooltip v-if="!showAnnotation" top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                fab
                x-large
                icon
                rounded
                dark
                absolute
                right
                top
                style="margin-top: 22%; margin-right: calc(11% + 4px);"
                class="v-card--raised"
                color="#FF8855"
                v-bind="attrs"
                v-on="on"
                @click="$emit('show-annotation')"
              >
                <v-icon> mdi-notebook-plus </v-icon>
              </v-btn>
            </template>
            <span>Notes</span>
          </v-tooltip>
        </div>
        <v-container v-if="isVideo" class="fill-height">
          <v-row justify="center" style="height: 100%">
            <video
              ref="video-control"
              class="preview_dimensions"
              style="border-style: solid; border-width: 1px; border-color: #e0e0e0"
              controls
            >
              Your browser does not support the video tag.
            </video>
            <v-row class="hidden-md-and-up mt-2 ml-2"
              >Uploaded on {{ shareDate.replaceAll("-", "/") }}</v-row
            >
            <slot name="lecture_is_video"></slot>
          </v-row>
        </v-container>

        <v-container v-else class="fill-height">
          <v-row justify="center" style="height: 100%">
            <v-img
              v-if="isImg"
              contain
              :src="previewUrl"
              class="preview_dimensions"
            ></v-img>
            <iframe
              v-else
              ref="iframe"
              :src="previewUrl"
              class="preview_dimensions"
            ></iframe>
            <!-- for debugging -->
            <div class="opaque-cover">{{ this.width }} x {{ this.height }}</div>
            <v-row class="hidden-md-and-up mt-2"
              >Uploaded on {{ shareDate }}</v-row
            >
            <slot name="lecture_is_not_video"></slot>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions v-if="isDownload">
        <v-btn @click="download">{{
          !$language ? "Download" : "ডাউনলোড"
        }}</v-btn>
      </v-card-actions>
    </v-card>
    <v-card style="width: 90vw" class="hidden-md-and-up">
      <slot name="lecture_mobile"></slot>
    </v-card>
  </div>
</template>

<script>
import { MediaPlayer } from "dashjs";

let player = undefined;
function attachQs(qs) {
  return () => ({
    modifyRequestHeader: function(xhr) {
      /* Add custom header. Requires to set up Access-Control-Allow-Headers in your */
      /* response header in the server side. Reference: https://developer.mozilla.org/en-US/docs/Web/API/XMLHttpRequest/setRequestHeader */
      /* xhr.setRequestHeader('DASH-CUSTOM-HEADER', 'MyValue'); */
      return xhr;
    },
    modifyRequestURL: function(url) {
      /* Modify url adding a custom query string parameter */
      return !url.includes("?") ? url + "?" + qs : url;
    },
  });
}

export default {
  name: "FilePreviewer",
  props: {
    isLecture: {
      type: Boolean,
      default: false,
    },
    showAnnotation: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
    previewUrl: {
      type: String,
      default: "",
      required: true,
    },
    downloadUrl: {
      type: String,
      default: "",
      required: true,
    },
    isVideo: {
      type: Boolean,
      default: true,
    },
    isDownload: {
      type: Boolean,
      default: false,
    },
    shareDate: {
      type: String,
      default: "date unavailable",
    },
  },
  data() {
    return {
      width: undefined,
      height: undefined,
    };
  },
  computed: {
    isImg() {
      let ext = this.previewUrl
        .split("?")[0]
        .split("/")
        .pop()
        .split(".")[1];
      return ["jpg", "jpeg", "png", "bmp", "gif"].includes(ext);
    },
  },
  watch: {
    previewUrl() {
      if (this.previewUrl)
        if (this.isVideo) {
          const pieces = this.previewUrl.split("?");
          if (pieces.length > 1) {
            player.extend("RequestModifier", attachQs(pieces[1]));
          }
          player.initialize(this.$refs["video-control"], this.previewUrl, true);
        } else this.$refs["iframe"].src += "";
    },
  },
  created() {
    window.addEventListener("resize", this.listenResize);
  },
  beforeMount() {
    player = MediaPlayer().create();
  },
  beforeDestroy() {
    player = undefined;
    window.removeEventListener("resize", this.listenResize);
  },
  methods: {
    download() {
      window.open(this.downloadUrl);
    },
    handler: function(e) {
      e.preventDefault();
    },
    listenResize(e) {
      this.width = e.target.innerWidth;
      this.height = e.target.innerHeight;
    },
  },
};
</script>

<style lang="scss">
.close-icon {
  position: absolute;
  top: 10px;
  right: 10px;
}
.preview_dimensions {
  height: 100%;
  @media (min-width: 960px) {
    width: 76%;
  }
  @media (max-width: 959px) {
    width: 100%;
  }
}
.opaque-cover {
  opacity: 0;
  height: 60px;
  width: 40%;
  left: 60%;
  position: absolute;
  @media screen and (max-width: 500px) {
    left: 0;
    width: 100%;
  }
}
</style>
